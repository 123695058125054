<template>
    <!-- in ios add pt-10 instead of pt-0-->
    <vue-loadmore
        :on-refresh="refresh_sr_tasks"
        :finished="false"
        style="width: 100%; direction: ltr"
        :pulling-text="$tr('Refresh')"
        :loosing-text="$tr('Refresh')"
        :refresh-text="`${$tr('Loading')}`"
        :loading-text="`${$tr('Loading')}`"
        :finished-text="$tr('Done')"
        :success-text="$tr('Done')"
    >
        <pull-to-refresh-banner ref="banner"></pull-to-refresh-banner>
        <v-container fluid :class="$ios ? 'pt-10' : 'pt-0'">
            <v-row class="">
                <v-col
                    cols="12"
                    class="d-flex align-center justify-space-between"
                >
                    <div class="page_title">
                        {{ $tr("Todays Tasks") }}
                    </div>
                    <v-btn
                        @click="show_add_task_dialog"
                        small
                        fab
                        elevation="4"
                        color="primary"
                        ><v-icon>mdi-plus</v-icon></v-btn
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-divider class="py-2"></v-divider>
            </v-row>

            <v-row class="px-0">
                <v-col cols="12" class="px-0">
                    <v-fade-transition
                        group
                        class="d-flex align-start flex-direction-row"
                    >
                        <v-card
                            color="basil"
                            :key="'main_card'"
                            flat
                            class="transparent px-0"
                            width="100%"
                            v-show="show_tasks"
                        >
                            <v-card-text class="pt-0">
                                <v-fade-transition group>
                                    <v-row
                                        class="px-2 pt-0 pb-4"
                                        v-for="(task, index) in tasks"
                                        :key="index"
                                    >
                                        <task-card 
                                        @refresh="refresh_sr_tasks"
                                        :task="task" />
                                    </v-row>
                                </v-fade-transition>
                            </v-card-text>
                        </v-card>

                        <template>
                            <v-container
                                v-show="show_skeleton"
                                :key="'skeleton'"
                                class="px-4"
                                fluid
                            >
                                <v-row class="mb-2" :key="'skeleton-1'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                                <v-row class="mb-2" :key="'skeleton-2'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                                <v-row class="mb-2" :key="'skeleton-3'">
                                    <v-skeleton-loader
                                        :loading="true"
                                        width="100%"
                                        type="article, actions"
                                    ></v-skeleton-loader>
                                </v-row>
                            </v-container>
                        </template>
                    </v-fade-transition>
                </v-col>
            </v-row>
        </v-container>
        <add-task-dialog
            ref="add_task_dialog"
            @refresh="refresh_sr_tasks"
            v-models:show="add_task_dialog.show"
        ></add-task-dialog>
    </vue-loadmore>
</template>

<script>
import { sync } from "vuex-pathify";

import TaskCard from "./Components/TaskCard/Index.vue";
import AddTaskDialog from "./Components/AddTaskDialog/Index.vue";

export default {
    components: {
        TaskCard,
        AddTaskDialog,
        PullToRefreshBanner: () => import("../Admin/Home/components/pullToRefreshBanner/Index.vue"),
    },
    data() {
        return {
            tasks: [],
            loading: false,
            show_tasks: true,
            show_skeleton: false,
            add_task_dialog: {
                show: false,
            },
        };
    },
    computed: {
        ...sync("user", {
            user: "user",
            loading_cover: "visualization@loading_cover",
        }),
    },
    methods: {
        async refresh(now = false) {
            try {
                const sr_todays_tasks = (
                    await this.$api.post("sr/tasks/fetch/today", null, {
                        sinceMins: 30,
                        now: now,
                    })
                ).data.results;
                this.tasks.splice(0);
                this.tasks.push(...sr_todays_tasks);
            } catch (error) {
                console.log(error);
            }
        },

        async set_loading() {
            this.show_tasks = false;
            this.show_skeleton = false;
            await this.$sleep(200);
            this.show_skeleton = true;
            // await this.$sleep(200);
        },
        async clear_loading() {
            this.show_skeleton = false;
            this.show_tasks = false;
            await this.$sleep(100);
            this.show_tasks = true;
        },
        async refresh_sr_tasks(done, show_banner = false, now = true) {
            await this.set_loading();
            await this.refresh(now);
            await this.clear_loading();
            if (show_banner) {
                this.$refs.banner.view_banner();
            }
            done?.();
        },
        show_add_task_dialog() {
            this.$refs.add_task_dialog.show = true;
        },
    },
    async mounted() {
        await this.refresh_sr_tasks(null, true, false);
    },
};
</script>

<style lang="sass">
.page_title
    font-size: 32px
    font-weight: 700
    padding-left: 16px
    font-family: Poppins
</style>
