<template>
    <v-dialog v-model="show" persistent :loading="loading" :disabled="loading">
        <v-card>
            <v-card-title class="text-h5">
                {{$tr("Delete Task")}}
            </v-card-title>
            <v-card-text>{{$tr("Do you want to Delte this Task?")}}</v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="show = false">
                    {{$tr("Close")}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="submit">
                    {{$tr("Submit")}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            loading: false,
        };
    },
    models: [{ data: "show", event: "models:show" }],
    watch: {
        show(v) {
            this.$emit("models:show", v);
        },
    },
    props: {
        task: null,
    },
    methods: {
        async submit() {
            this.loading = true;
            try {
                await this.$api.post("/sr/tasks/delete/one", { task_id: this.task?.task_id });

                this.show = false;
                this.$emit("refresh");
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
    },
};
</script>
